body {
  text-align: center;
  font-family: sans-serif;
  background-color: #d1e0eb;
  margin: 0;
  padding: 0;
}

.app-container {
  padding: 20px;
}

.setup-container {
  max-width: 20em;
  margin: 0 auto;
}

.timer-container {
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  overflow: hidden;
}

.timer-display {
  width: 92%;
  padding: 20px;
  border: 5px solid #666;
  border-radius: 20px;
  font-size: 4em;
  background-color: white;
  margin-bottom: 20px;
  font-family: monospace;
}

h2, h3 {
  font-size: 1.2em;
  margin: 10px 0;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

button {
  background-color: #3a4764;
  border-radius: 6px;
  width: 120px;
  height: 40px;
  margin: 10px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  border: none;
  box-shadow: 0 7px #999;
}

button:hover {
  background-color: #59c1e3;
}

button:active {
  transform: translateY(4px);
  box-shadow: 0 4px #666;
}

#timer {
  font-size: 3em;
  margin: 20px auto;
  text-align: center;
}

.input-box {
  margin: 10px 0;
  padding: 5px;
  width: 100px;
  text-align: center;
  font-size: 1em;
}

@media (max-width: 600px) {
  .timer-display {
    font-size: 2em;
  }

  .button {
    padding: 8px;
    font-size: 0.9em;
  }

  h2, h3 {
    font-size: 1em;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.popup-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.popup .button {
  margin: 0 10px;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 5px;
  background-color: #76c7c0;
}

.progress-bar-fill {
  height: 100%;
  background-color: #76c7c0;
}